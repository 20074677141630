:root {
    --orange: #ff6e00;
    --orange-dark: #d45c00;
    --black: #000;
    --white: #ffffff;
    --button-padding: 15px 30px 15px 30px;
    --gap10: 10px;
    --gap20: 20px;
    --gap30: 30px;
    --f20: 20px;
    --f16: 16px;
    --button-radius: 5px;
    --radius10: 10px;
    --dflex: flex;
    --radius-left: 15px 0px 0px 15px;
    --radius-right: 0 15px 15px 0;
    --gradient: linear-gradient(180deg, #FFF 0%, #ffede5 100%);
    --container-width:1365px;
}
#main {
    max-width: 1920px;
    margin: 0 auto;
    width: auto;
    float: none;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.30);
}
.mb-0 {
    margin-bottom: 0;
}
.mb-5 {
    margin-bottom: 5px !important;
}
.mb-15 {
    margin-bottom: 15px !important;
}
.button-block {
    margin-top: 20px;
}
.button-block.right {
    text-align: right;
}
img {
    max-width: 100%;
}
body {
    font-size: 15px;
    font-family: 'Exo 2';
    color: #000000;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
p,
ul {
    margin: 0;
    padding: 0;
    font-family: 'Exo 2', sans-serif;
    font-weight: 500;
}
p {
    line-height: 22px;
    margin-bottom: 12px;
    text-align: justify;
}
p {
    font-size: 16px;
    color: #000000;
    line-height: 22px;
    margin-bottom: 10px;
    text-align: justify;
    display: block;
    font-family: 'Exo 2';
    font-weight: 500;
}
p b {
    font-family: 'Exo 2';
    font-weight: bold;
}
p:last-child {
    margin: 0;
}
footer p {
    text-align: left;
}
.clear {
    clear: both;
    display: block;
}
a {
    color: #FF6E00;
}
a:hover {
    color: #363636;
}
.button-block.d-flex {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}
.banner-section strong.themecolor1 {
    margin-top: 0 !important;
}
.play-anim-ico {
    background-image: url(../images/home-new/play-button.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 60px;
    position: absolute;
    height: 60px;
    left: 50%;
    top: 50%;
    z-index: 111;
    margin-top: -30px;
    margin-left: -30px;
}
.play-anim-ico.orange {
    background-image: url(../images/home-new/play-button-org.svg);
    background-color: #fff;
    border-radius: 50%;
}
.home-new-version .myproduct-cnt .new-tag {
    position: absolute;
    left: -7px;
    top: -5px;
    width: 90px;
}
.play-anim-ico:after {
    position: absolute;
    width: 34px;
    height: 34px;
    content: '';
    left: 50%;
    top: 50%;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    margin: -17px 0 0 -17px;
    border-radius: 50%;
    z-index: -1;
    box-shadow: 0 0 0 13px #fff;
    transform: scale(0.9);
    transform-origin: center center;
    animation: pulse-me 1s linear infinite;
    -webkit-animation: pulse-me 1s linear infinite;
}
.play-anim-ico.orange:after {
    box-shadow: 0 0 0 13px #ff6e00;
}
.play-anim-ico.orange.small:after {
    width: 20px;
    height: 20px;
    margin: -10px 0 0 -10px;
    animation: pulse-me-small 1s linear infinite;
    -webkit-animation: pulse-me-small 1s linear infinite;
}
@keyframes pulse-me {
    0% {
        transform: scale(.9);
        opacity: .2;
        -webkit-transform: scale(.9);
        -moz-transform: scale(.9);
        -ms-transform: scale(.9);
        -o-transform: scale(.9)
    }
    50% {
        opacity: .5
    }
    70% {
        opacity: .09
    }
    100% {
        transform: scale(2.1);
        opacity: 0;
        -webkit-transform: scale(2.1);
        -moz-transform: scale(2.1);
        -ms-transform: scale(2.1);
        -o-transform: scale(2.1);
    }
}
@keyframes pulse-me-small {
    0% {
        transform: scale(.9);
        opacity: .2;
        -webkit-transform: scale(.9);
        -moz-transform: scale(.9);
        -ms-transform: scale(.9);
        -o-transform: scale(.9)
    }
    50% {
        opacity: .5
    }
    70% {
        opacity: .09
    }
    100% {
        transform: scale(1.8);
        opacity: 0;
        -webkit-transform: scale(1.8);
        -moz-transform: scale(1.8);
        -ms-transform: scale(1.8);
        -o-transform: scale(1.8);
    }
}
ul.common-listing {
    margin-bottom: 12px;
    flex-direction: column;
}
ul.common-listing.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
}
ul.common-listing.grid li {
    margin-bottom: 0;
}
ul.common-listing li {
    margin-bottom: 10px;
    position: relative;
    display: block;
    padding: 0 0 0 28px;
    min-height: 20px;
}
ul.common-listing li:before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0;
    top: 0;
    background-image: url(../images/home-page/check.svg);
    background-size: 20px;
    background-repeat: no-repeat;
}
.common-btn {
    background-color: var(--orange);
    color: var(--white);
    padding: var(--button-padding);
    gap: var(--gap20);
    font-size: var(--f16);
    border-radius: var(--button-radius);
    -webkit-border-radius: var(--button-radius);
    -moz-border-radius: var(--button-radius);
    -ms-border-radius: var(--button-radius);
    -o-border-radius: var(--button-radius);
    line-height: 1em;
    display: inline-flex;
    align-items: center;
    gap: var(--gap10);
    font-weight: 400;
    font-family: 'Exo 2';
    border: none;
    outline: none;
    transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    z-index: 0;
    position: relative;
    overflow: hidden;
    text-transform: capitalize;
    cursor: pointer;
    text-align: left;
}
.common-btn::before {
    background: #000;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    z-index: -1;
    pointer-events: none;
}
.common-btn:hover::before {
    transform: scaleX(1);
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    -o-transform: scaleX(1);
}
.common-btn img {
    width: 18px;
    height: 13px;
    display: inline-block;
    font-size: 0;
    vertical-align: middle;
}
.common-btn._VIDEO_ img {
    width: 20px;
    height: 20px;
}
.common-btn:hover {
    color: var(--white);
}
.common-btn.white_bg{
    background-color: var(--white);
    color: var(--orange);
}
.common-btn.white_bg:hover{
    color: #fff;
}
.common-btn.white_bg:hover img{
    filter: brightness(0) invert(1);
    -webkit-filter: brightness(0) invert(1);
}
.relative {
    position: relative;
}
.text-center {
    text-align: center;
}
.common-page-hd {
    font-size: 38px;
    color: var(--orange-dark);
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1em;
    margin-bottom: 5px;
    font-family: 'Exo 2';
    margin-top: -5px;
}
.common-page-hd.small {
    font-size: 28px;
    margin-bottom: 10px;
}
.common-page-hd.white-page-hd ~ strong {
    color: var(--white);
}
.common-page-hd ~ strong {
    font-size: 20px;
    display: block;
    color: #000000;
    margin-top: 10px;
    line-height: 20px;
    position: relative;
    padding-top: 20px;
    text-transform: uppercase;
    margin: 0 0 15px 0;
}
.common-page-hd ~ strong:before {
    content: '';
    display: block;
    width: 120px;
    height: 5px;
    position: absolute;
    left: 0;
    top: 0;
    background-image: url(../images/home-page/sep.png);
}
.common-page-hd ~ strong.nobefore:before {
    display: none;
}
.common-page-hd.center ~ strong:before {
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
}
.common-page-hd strong {
    font-size: 22px;
    display: block;
    position: relative;
    text-transform: uppercase;
}
.common-page-hd strong:before {
    content: '';
    display: inline-block;
    width: 40px;
    height: 2px;
    background: #000;
    vertical-align: middle;
    margin-right: 10px;
}
.common-page-hd span::before {
    content: '';
    display: block;
    width: 120px;
    height: 5px;
    position: absolute;
    left: 0;
    top: 0;
    background-image: url(../images/home-page/sep.png);
}
.common-page-hd.orange_white_sep span::before {
    background-image: url(../images/home-page/sep-orange-white.png);
}
.common-page-hd span {
    font-size: 20px;
    display: block;
    color: var(--black);
    margin-top: 10px;
    line-height: 20px;
    position: relative;
    padding-top: 20px;
}
.banner-section-inner {
    max-width: 1400px;
    padding: 0 15px;
    margin: 0 auto;
    height: calc(100vh - 176px);
    height: -o-calc(100vh - 176px);
    height: -moz-calc(100vh - 176px);
    height: -webkit-calc(100vh - 176px);
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
}
.banner-section {
    padding: 120px 0 0 0;
    overflow: hidden;
    background-image: radial-gradient(at 36% 63%,#e6f7ff 0,transparent 50%),radial-gradient(at 15% 77%,#e6f7ff 0,transparent 50%),radial-gradient(at 12% 25%,#fff8f3 0,transparent 50%),radial-gradient(at 43% 12%,#f5feff 0,transparent 50%),radial-gradient(at 64% 64%,#fff8f3 0,transparent 50%),radial-gradient(at 100% 0,#e6f7ff 0,transparent 50%);
}
.banner-section .heading-caption {
    margin: 0 0 15px 0;
}
.banner-section h1 {
    color: #000000;
    font-size: 46px;
    font-style: normal;
    font-weight: bold;
    line-height: 1em;
    margin-bottom: 15px;
}
.banner-section h1 strong,
.banner-section h1 + strong {
    color: var(--orange);
    font-weight: bold;
    margin-bottom: 0px;
    font-size: 36px;
    line-height: 1.2em;
}
.banner-section p b {
    display: inline;
    color: #090909;
    font-weight: bold;
}
.banner-section strong {
    font-size: 32px;
    font-weight: bold;
    color: #000000;
    line-height: normal;
    margin-bottom: 14px;
    margin-top: 12px;
    display: block;
    font-family: 'Exo 2';
    font-weight: bold;
}
.banner-section ul {
    max-width: 100%;
}
.banner-section ul li {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: normal;
    font-weight: 400;
    position: relative;
    padding-left: 21px;
    color: #202020;
    font-family: 'Exo 2';
    text-align: justify;
}
.banner-section ul li:first-child,
.banner-section ul li:nth-child(2) {
    font-size: 15px;
}
.banner-section ul li::before {
    content: "";
    width: 6px;
    height: 6px;
    font-weight: 500;
    color: rgb(255, 255, 255);
    position: absolute;
    left: 0px;
    top: 8px;
    background-color: #ff6e00;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.banner_contact-area {
    max-width: 1370px;
    margin: 0 auto;
    padding: 0 15px;
    display: grid;
    justify-content: space-between;
    grid-template-columns: auto auto;
}
ul.banner_contact-data {
    display: flex;
    flex-wrap: wrap;
    gap: 45px;
}
ul.banner_contact-data li {
    padding: 0;
    margin: 0;
}
ul.banner_contact-data li:before {
    display: none;
}
.banner-left {
    padding-top: 20px;
    position: relative;
}
.banner-left:before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 100%;
}
.banner-left img {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
ul.banner_contact-data li a {
    margin: 0;
    padding: 0;
    display: inline-flex;
    flex-wrap: wrap;
    gap: 10px;
    color: #000;
    font-family: 'Exo 2';
    font-size: 16px;
    font-weight: 600;
}
ul.banner_contact-data li a img {
    width: 30px;
    height: 25px;
    display: inline-block;
    font-size: 0;
    vertical-align: top;
}
.banner_contact {
    stroke-width: 1px;
    stroke: #E6E6E6;
    padding: 16px 0;
    position: relative;
    z-index: 0;
}
ul.quick-links {
    display: flex;
    flex-wrap: wrap;
    gap: 45px;
}
ul.quick-links li {
    padding: 0 0 0 13px;
}
.banner-section ul.quick-links li {
    margin: 0;
    padding: 0;
}
.banner-section ul.quick-links li::before {
    display: none;
}
ul.quick-links li a {
    color: #000;
    text-transform: uppercase;
    font-weight: 600;
    border-bottom: 1px solid var(--orange);
    padding-bottom: 3px;
}
ul.quick-links li::before {
    content: "";
    width: 6px;
    height: 6px;
    font-weight: 500;
    color: rgb(255, 255, 255);
    position: absolute;
    left: 0px;
    top: 9px;
    background-color: var(--orange);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
/***************************************/
.main-product-clone {
    padding: 40px 0;
}
.main-product-clone-inner {
    max-width: var(--container-width);
    margin: 0 auto;
    padding: 0 15px;
}
.main-product-clone-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    margin: 15px 0 0 0;
}
.main-product-clone .main-product-clone-right > a {
    display: block;
    position: relative;
}
.main-product-clone .main-product-clone-right > a:before {
    content: '';
    display: block;
    width: 100%;
    padding-top:78.70%;
}
.main-product-clone .main-product-clone-right > a > img {
    position: absolute;
    left: 0;
    top: 0;
}
.main-product-clone .main-product-clone-right > a > .play-anim-ico {
    position: absolute;
    top: 40%;
    width: 35px;
    height: 35px;
}
.main-product-clone .button-block.d-flex {justify-content: center;}
/***************************************/
.gojek-clone-app {
    padding: 40px 0;
    background-color: #F8F8F8;
}
.gojek-clone-app.reverse {
    padding: 20px 0 28px 0;
}
.gojek-clone-app.reverse ~ .gojek-clone-app {
    padding: 0;
    background: linear-gradient(180deg, #f8f8f8 0%, #fff 100%);
}
section.gojek-clone-app.reverse .screens_holder {
    left: auto;
    right: 20px;
    display: flex;
    justify-content: flex-end;
}
.gojek-clone-app-inner {
    max-width: var(--container-width);
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    flex-wrap: wrap;
    gap: var(--gap30);
}
.gojek-clone-app .heading-area {
    position: relative;
}
.gojek-clone-app-right {
    padding: 0;
    position: relative;
    z-index: 0;
    padding: 30px 20px 30px 0;
    justify-content: flex-end;
    width: 45%;
}
.gojek-clone-app-right::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 50vw;
    height: auto;
    background-color: #f1f1f1;
    z-index: -1;
    border-radius: var(--radius-left);
    -webkit-border-radius: var(--radius-left);
    -moz-border-radius: var(--radius-left);
    -ms-border-radius: var(--radius-left);
    -o-border-radius: var(--radius-left);
}
.gojek-clone-app-right .screen {
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
}
.gojek-clone-app-right .screen:first-child {
    position: relative;
    width: 45%;
}
.gojek-clone-app-right .screen:last-child {
    position: relative;
    width: 45%;
}
.gojek-clone-app-right .screen::before {
    content: '';
    padding-top: 204.74%;
    width: 100%;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
}
.gojek-clone-app-left {
    min-height: 587px;
    display: var(--dflex);
    justify-content: flex-start;
    flex-direction: column;
    width: calc(55% - 30px);
    -o-width: calc(55% - 30px);
    -moz-width: calc(55% - 30px);
    -webkit-width: calc(55% - 30px);
}
.screens_holder {
    position: absolute;
    left: 20px;
    top: 19px;
    width: 100%;
    display: var(--dflex);
    gap: var(--gap20);
    bottom: 0;
}
/* .gojek-clone-app-right img:first-child {
    position: relative;
    top: -40px;
    width: 46.051%;
}
.gojek-clone-app-right img:last-child {
    position: relative;
    bottom: -40px;
    width: 46.051%;
} */
.gojek-clone-app.reverse .gojek-clone-app-right::after {
    left: auto;
    right: 0;
    border-radius: var(--radius-right);
    -webkit-border-radius: var(--radius-right);
    -moz-border-radius: var(--radius-right);
    -ms-border-radius: var(--radius-right);
    -o-border-radius: var(--radius-right);
}
.gojek-clone-app.reverse .gojek-clone-app-right {
    padding: 30px 20px 30px 0;
    justify-content: flex-end;
    width: 45%;
}
.gojek-clone-app.reverse .gojek-clone-app-left {
    width: calc(55% - 30px);
    -o-width: calc(55% - 30px);
    -moz-width: calc(55% - 30px);
    -webkit-width: calc(55% - 30px);
}
/*************************************/
.innovative-solutions {
    padding: 40px 0 0 0;
    background-color: #F8F8F8;
}
.innovative-solutions-inner {
    max-width: var(--container-width);
    margin: 0 auto;
    padding: 0 15px;
}
/*************************************/
.apps-launched {
    padding: 56px 0 40px 0;
    background: var(--gradient);
}
.apps-launched-inner {
    max-width: var(--container-width);
    margin: 0 auto;
    padding: 0 15px;
    display: grid;
    grid-template-columns: 0.5fr 2fr;
    gap: var(--gap30);
    align-items: center;
}
/*************************************/
.statistics {
    padding: 40px 0 35px 0;
}
.statistics-inner {
    max-width: var(--container-width);
    padding: 0 15px;
    margin: 0 auto;
}
.statistics-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--gap30);
    align-items: center;
}
ul.statistics-list li strong {
    height: 128px;
    display: var(--dflex);
    align-items: center;
    justify-content: center;
    font-size: 40px;
    color: #1D1E31;
    border-radius: var(--radius10);
    -webkit-border-radius: var(--radius10);
    -moz-border-radius: var(--radius10);
    -ms-border-radius: var(--radius10);
    -o-border-radius: var(--radius10);
    z-index: 0;
    position: relative;
    font-family: 'Exo 2';
    font-weight: bold;
}
ul.statistics-list li strong::before {
    content: '';
    position: absolute;
    top: 5.3px;
    left: 5.3px;
    width: 100%;
    height: 100%;
    border: 1.06px dashed #BCBCBC;
    border-radius: var(--radius10);
    -webkit-border-radius: var(--radius10);
    -moz-border-radius: var(--radius10);
    -ms-border-radius: var(--radius10);
    -o-border-radius: var(--radius10);
    transition: .3s ease-in-out;
    -webkit-transition: .3s ease-in-out;
    -moz-transition: .3s ease-in-out;
    -ms-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
}
ul.statistics-list li strong:hover::before {
    top: -5px;
    left: -5px;
}
ul.statistics-list {
    gap: 35px 50px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
ul.statistics-list li {
    text-align: center;
}
ul.statistics-list li span {
    margin-top: 20px;
    font-size: 16px;
    color: var(--black);
    display: block;
    font-family: 'Exo 2';
    font-weight: 500;
}
/*************************************/
.clients-section {
    padding: 40px 0;
    background-color: #EDF3F4;
}
.clients-section-inner {
    max-width: var(--container-width);
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: 30px;
}
.clients-left {
    width: calc(45% - 15px);
    -o-width: calc(45% - 15px);
    -moz-width: calc(45% - 15px);
    -webkit-width: calc(45% - 15px);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-top: 2px;
}
.clients-right {
    width: calc(55% - 15px);
    -o-width: calc(55% - 15px);
    -moz-width: calc(55% - 15px);
    -webkit-width: calc(55% - 15px);
}
.clients-holder {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    width: 100%;
}
.client-img1 {
    position: relative;
}
[class*="client-img"] a .play-anim-ico {
    position: absolute;
    width: 40px;
    height: 40px;
    z-index: 0;
    margin: -10px -10px;
    left: auto;
    top: 20px;
    right: 20px;
}
.client-img1 a {
    display: block;
    overflow: hidden;
}
.no_video a {
    pointer-events: none;
}
.no_video .play-anim-ico {
    display: none;
}
.client-img1 a:before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 78.5%;
}
.client-img1 img {
    max-width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: var(--radius10);
    -webkit-border-radius: var(--radius10);
    -moz-border-radius: var(--radius10);
    -ms-border-radius: var(--radius10);
    -o-border-radius: var(--radius10);
}
/*************************************/
.prelaunch-event {
    padding: 40px 0 40px 0;
}
.prelaunch-event-inner {
    max-width: var(--container-width);
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.prelaunch-left {
    width: 57%;
}
.prelaunch-right {
    width: 40%;
}
.prelaunch-video {
    width: 41.5vw;
    display: block;
    position: relative;
}
.prelaunch-video::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 69.44%;
}
.prelaunch-video img {
    max-width: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
/*************************************/
.our-products-section {
    padding: 40px 0 40px 0;
    background-color: #f8f8f8;
}
.our-products-section-inner {
    max-width: var(--container-width);
    padding: 0 15px;
    margin: 0 auto;
}
.our-products-section .heading-area {
    margin-bottom: 20px;
}
.our-products-section ul {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px 20px;
}
.our-products-section ul li img {
    width: 52px;
    height: 52px;
    display: inline-block;
    font-size: 0;
    vertical-align: top;
}
.our-products-section ul li a {
    box-shadow: 0px 23.77151px 31.69535px 0px rgba(0, 0, 0, 0.07);
    display: block;
    padding: 20px;
    text-align: center;
    min-height: 100%;
    position: relative;
    background-color: #fff;
}
.our-products-section ul li b {
    font-size: 18px;
    color: #000;
    font-family: 'Exo 2', sans-serif;
    font-weight: bold;
    margin-top: 15px;
    line-height: 1em;
    display: block;
}
.our-products-section ul li {
    padding: 20px;
    position: relative;
    z-index: 0;
}
.our-products-section ul li span {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 90px;
    z-index: -1;
}
/*************************************/
.launching {
    padding: 40px 0;
    background-color: #000;
    /* background-image: url(../images/home-page/bg.png);
    background-repeat: no-repeat;
    background-size: cover; */
}
.launching-right img {
    vertical-align: top;
}
.launching + .features {
    background-color: #fff;
}
.launching .common-page-hd ~ strong:before {
    background-image: url(../images/home-page/sep-orange-white.png);
}
.text-white p {
    color: var(--white);
}
.text-white .common-page-hd {
    color: var(--orange);
}
.text-white .common-page-hd ~ strong {
    color: var(--white);
}
.text-white .common-page-hd strong:before {
    background-color: var(--white);
}
.launching-inner {
    max-width: var(--container-width);
    margin: 0 auto;
    padding: 0 15px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 50px;
    align-items: center;
}
.launching .common-btn::before {
    background-color: #ffffff;
}
.launching .common-btn:hover {
    color: #000;
}
.launching .common-btn img {
    transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
}
.launching .common-btn:hover img {
    filter: invert(1);
    -webkit-filter: invert(1);
}
/*************************************/
.features {
    padding: 40px 0;
}
.features-inner {
    max-width: var(--container-width);
    margin: 0 auto;
    padding: 0 15px;
}
ul.features-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 22px;
}
ul.features-row li {
    /*border: 1px solid #D6D6D6;
    */box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 20px;
    background-color: #fff;
    text-align: center;
    transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
}
ul.features-row li:hover {
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.3);
}
ul.features-row li i {
    width: 60px;
    height: 60px;
    display: block;
    display: inline-block;
}
ul.features-row li i img {
    width: 60px;
    height: 60px;
    font-size: 0;
    display: inline-block;
    vertical-align: top;
}
ul.features-row li strong {
    display: block;
    font-family: 'Exo 2';
    font-weight: bold;
    font-size: 18px;
    margin: 10px 0 8px 0;
}
/*************************************/
.clients-visitus {
    padding: 40px 0 40px 0;
    background-color: #f8f8f8;
}
.clients-visitus-inner {
    max-width: var(--container-width);
    margin: 0 auto;
    padding: 0 15px;
}
.clients-visitus ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}
.clients-visitus ul li {
    box-shadow: 0px 0px 24.98047px -4.16341px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.clients-visitus ul li:not(:last-child) {
    cursor: pointer;
}
ul.features-row li .features-title-area {
    display: grid;
    grid-template-columns: 70px auto;
    align-items: center;
    margin-bottom: 10px;
}
.clients-visitus ul li .clients-visitus-caption {
    padding: 10px 10px 8px 10px;
}
.clients-visitus ul li .clients-visitus-caption strong {
    margin-bottom: 5px;
    display: block;
    font-weight: 600;
    font-size: 18px;
}
.clients-visitus ul li .big-button {
    width: 100%;
    padding: 10px;
    height: 100%;
}
.clients-visitus ul li .name_video {
    text-align: right;
    padding: 0 10px 10px 10px;
}
.clients-visitus ul li .clients_image{
    position: relative;
}
.clients-visitus ul li .clients_image p {
    font-size: 15px;
}
.clients-visitus ul li .clients_image_hold {
    position: relative;
}
.clients-visitus ul li .clients_image_hold::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 49.5%;
}
.clients-visitus ul li .clients_image_hold > img.main_client_img {
    position: absolute;
    left: 0;
    top: 0;
}
.clients-visitus ul li .video_icon {
    width: 31px;
    height: 31px;
    display: inline-block;
    font-size: 0;
    cursor: pointer;
    position: absolute;
    right: 10px;
    bottom: 10px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    box-shadow: 0 0 10px rgba(0,0,0,0.4);
    -webkit-box-shadow: 0 0 10px rgba(0,0,0,0.4);
}
.clients-visitus ul li .play-anim-ico {
    width: 31px;
    height: 31px;
    display: inline-block;
    font-size: 0;
    cursor: pointer;
    position: absolute;
    right: 10px;
    bottom: 10px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    box-shadow: 0 0 10px rgba(0,0,0,0.4);
    -webkit-box-shadow: 0 0 10px rgba(0,0,0,0.4);
    left: auto;
    top: auto;
}
.clientname {
    font-weight: 500;
}
.client-img img {
    border-radius: 10px 10px 0 0;
}
.clients-visitus .heading-area p {
    margin-bottom: 20px;
}
.clients-visitus ul li .big-button .common-btn {
    min-height: 100%;
    width: 100%;
    font-size: 34px;
    line-height: 1em;
    font-weight: 600;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
}
.clients-visitus ul li .big-button .common-btn:hover {
    background-color: var(--orange-dark);
}
.clients-visitus ul li .big-button .common-btn:before {
    content: '';
    position: absolute;
    left: 14px;
    top: 14px;
    bottom: 14px;
    right: 14px;
    border-width: 1px;
    border-style: solid;
    border-color: #fff;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    transform: none;
    background-color: transparent;
    pointer-events: none;
}
/*************************************/
.angola {
    padding: 40px 0;
}
.angola .heading-area {
    margin: 0 0 10px 0;
}
.angola-inner {
    max-width: var(--container-width);
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.angola-left {
    width: calc(55% - 15px);
    -o-width: calc(55% - 15px);
    -moz-width: calc(55% - 15px);
    -webkit-width: calc(55% - 15px);
}
.angola-right {
    width: calc(45% - 15px);
    -o-width: calc(45% - 15px);
    -moz-width: calc(45% - 15px);
    -webkit-width: calc(45% - 15px);
}
.kubinga-image-block {
    display: block;
    width: 42vw;
    position: relative;
}
.kubinga-image-block::before {
    content: '';
    display: block;
    padding-top: 46%;
    width: 100%;
}
.kubinga-image-block img {
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
/*************************************/
.realcompany-section {
    position: relative;
    padding: 40px 0;
    background-color: #f8f8f8;
}
/* .realcompany-section ~ .features {
    background-color: #f8f8f8;
} */
.realcompany-section-inner {
    max-width: var(--container-width);
    padding: 0 15px;
    margin: 0 auto;
}
.masonry-block_ img {
    width: 100%;
    vertical-align: top;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.masonry-block_ {
    overflow: hidden;
}
.masonry-block_ img {
    margin-bottom: 10px;
}
.masonry-block_.col-33 {
    width: calc(33.33% - 10px);
    width: -o-calc(33.33% - 10px);
    width: -ms-calc(33.33% - 10px);
    width: -moz-calc(33.33% - 10px);
    width: -webkit-calc(33.33% - 10px);
}
.masonry-block_.col-7 {
    width: calc(60.7% - 10px);
    width: -o-calc(60.7% - 10px);
    width: -ms-calc(60.7% - 10px);
    width: -moz-calc(60.7% - 10px);
    width: -webkit-calc(60.7% - 10px);
}
.masonry-block_.col-5 {
    width: calc(39.3% - 10px);
    width: -o-calc(39.3% - 10px);
    width: -ms-calc(39.3% - 10px);
    width: -moz-calc(39.3% - 10px);
    width: -webkit-calc(39.3% - 10px);
}
.masonry-block_.col-6 {
    width: calc(52.4% - 10px);
    width: -o-calc(52.4% - 10px);
    width: -ms-calc(52.4% - 10px);
    width: -moz-calc(52.4% - 10px);
    width: -webkit-calc(52.4% - 10px);
}
.masonry-block_.col-9 {
    width: calc(75% - 10px);
    width: -o-calc(75% - 10px);
    width: -ms-calc(75% - 10px);
    width: -moz-calc(75% - 10px);
    width: -webkit-calc(75% - 10px);
}
.masonry-block_.col-3 {
    width: calc(24.32% - 10px);
    width: -o-calc(24.32% - 10px);
    width: -ms-calc(24.32% - 10px);
    width: -moz-calc(24.32% - 10px);
    width: -webkit-calc(24.32% - 10px);
}
.masonry-block_.col-3_1 {
    width: calc(23.2% - 10px);
    width: -o-calc(23.2% - 10px);
    width: -ms-calc(23.2% - 10px);
    width: -moz-calc(23.2% - 10px);
    width: -webkit-calc(23.2% - 10px);
}
.masonry-block_.col-6_1 {
    width: calc(52% - 10px);
    width: -o-calc(52% - 10px);
    width: -ms-calc(52% - 10px);
    width: -moz-calc(52% - 10px);
    width: -webkit-calc(52% - 10px);
}
.masonry-block_.col-6_2 {
    width: calc(48% - 10px);
    width: -o-calc(48% - 10px);
    width: -ms-calc(48% - 10px);
    width: -moz-calc(48% - 10px);
    width: -webkit-calc(48% - 10px);
}
.masonry-block_.col-5_1 {
    width: calc(46.3% - 10px);
    width: -o-calc(46.3% - 10px);
    width: -ms-calc(46.3% - 10px);
    width: -moz-calc(46.3% - 10px);
    width: -webkit-calc(46.3% - 10px);
}
.masonry-block_.col-3_2 {
    width: calc(26.6% - 10px);
    width: -o-calc(26.6% - 10px);
    width: -ms-calc(26.6% - 10px);
    width: -moz-calc(26.6% - 10px);
    width: -webkit-calc(26.6% - 10px);
}
.masonry-block_.col-3_3 {
    width: calc(27% - 10px);
    width: -o-calc(27% - 10px);
    width: -ms-calc(27% - 10px);
    width: -moz-calc(27% - 10px);
    width: -webkit-calc(27% - 10px);
}
.masonry {
    margin-top: 20px;
}
.masonry ._row_ {
    display: flex;
    flex-wrap: wrap;
    display: none;
    gap: 10px;
}
.masonry-block_ a.view-more {
    display: block;
    cursor: pointer;
    background-color: #FF6E00;
    position: relative;
    transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
}
.masonry-block_ a.view-more:hover {
    background-color: #a74500;
}
.masonry-block_ a.view-more img {
    margin-bottom: 0;
}
.masonry-block_ a.view-more>span {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    font-weight: bold;
    font-size: 28px;
}
.masonry-block_ a.view-more {
    display: block;
    cursor: pointer;
    background-color: var(--orange);
    position: relative;
    transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.masonry-block_ a.view-more:hover {
    background-color: var(--orange-dark);
}
.masonry-block_ a.view-more img {
    margin-bottom: 0;
}
.masonry-block_ a.view-more>span {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    font-weight: bold;
    font-size: 34px;
}
.masonry-block_ a.view-more:before {
    content: '';
    position: absolute;
    left: 14px;
    top: 14px;
    bottom: 14px;
    right: 14px;
    border-width: 1px;
    border-style: solid;
    border-color: #fff;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.masonry ._row_.have_more .masonry-block_:last-child {
    display: none;
}
.masonry.open ._row_.have_more .masonry-block_:last-child {
    display: block;
}
.masonry ._row_:nth-child(-n+3) {
    display: flex;
}
.masonry.open ._row_ {
    display: flex;
}
/*************************************/
.success {
    padding: 40px 0 30px 0;
}
.success-inner {
    max-width: var(--container-width);
    margin: 0 auto;
    padding: 0 15px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    align-items: center;
}
.first-billion-section {
    position: relative;
    background-color: #000;
    padding: 40px 0;
    background-size: cover;
}
.first-billion-section .common-btn::before {
    background-color: #ffffff;
}
.first-billion-section .common-btn:hover {
    color: #000;
}
.first-billion-section .common-btn:hover img {
    filter: invert(1);
    -webkit-filter: invert(1);
}
.first-billion-section .common-page-hd ~ strong:before {
    background-image: url(../images/home-page/sep-white.png);
}
.first-billion-img img {
    vertical-align: top;
}
.first-billion-section-inner {
    max-width: var(--container-width);
    padding: 0 15px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 0.5fr 2fr;
    gap: 30px;
    align-items: center;
}
.first-billion-cnt {
    color: #fff;
    display: flex;
    flex-direction: column;
}
.webelievein-cnt p {
    line-height: 22px;
    margin-bottom: 0;
}
.first-billion-cnt p {
    color: #fff;
}

/*************************************/
/*************************************/
.core-values {
    padding: 40px 0;
}
.core-values-inner {
    max-width: var(--container-width);
    margin: 0 auto;
    padding: 0 15px;
}
ul.core-values-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}
ul.core-values-row li {
    border: 1px solid #D6D6D6;
    border-radius: 15px;
    padding: 20px;
    background-color: #fff;
}
ul.core-values-row li i {
    width: 50px;
    height: 50px;
    display: block;
    font-size: 50px;
    color: var(--orange);
}
ul.core-values-row li strong {
    display: block;
    font-family: 'Exo 2';
    font-weight: bold;
    font-size: 18px;
    margin: 10px 0 10px 0;
}
/*************************************/
/*************************************/
.our-process {
    padding: 40px 0;
    position: relative;
    z-index: 0;
}
.our-process-inner {
    padding: 0 15px;
    max-width: var(--container-width);
    margin: 0 auto;
}
.our-process ul {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    margin-top: 15px;
    position: relative;
    z-index: 1;
}
.our-process ul li strong {
    font-family: 'Exo 2';
    font-size: 18px;
    display: block;
    margin: 0 0 10px 0;
    font-weight: bold;
    text-align: center;
    line-height: 1em;
}
.our-process ul li {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.our-process ul li .our-process-caption {
    padding: 14px 8px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    position: relative;
    height: calc(100% - 116px);
    height: -o-calc(100% - 116px);
    height: -moz-calc(100% - 116px);
    height: -webkit-calc(100% - 116px);
}
.our-process ul li p {
    text-align: justify;
}
.our-process ul li i {
    display: inline-flex;
    clip-path: polygon(
        49.47% 0%,
        98.83% 24.84%,
        98.83% 74.52%,
        49.47% 99.35%,
        0% 74.52%,
        0% 24.84%
    );
    width: 75px;
    height: 82px;
    position: relative;
    margin-bottom: 35px;
    justify-content: center;
    align-items: center;
}
.our-process ul li i span {
    color: #fff;
    font-size: 35px;
    font-weight: 800;
    justify-content: center;
    align-items: center;
    font-style: normal;
    display: block;
    margin-top: -5px;
}
.our-process ul li i:before {
    content: '';
    display: block;
    height: 93px;
    position: absolute;
    right: 0;
    border-left: 40px solid rgba(0,0,0,0.1);
}
.our-process ul li .our-process-caption::before {
    content: '';
    width: 4.80px;
    height: 30px;
    display: block;
    position: absolute;
    background-image: url(../images/home-page/vline.svg);
    left: 50%;
    transform: translateX(-50%);
    top: -35px;
}
/*************************************/
.visit-memorable-client {
    padding: 40px 0 40px 0;
    background-color: #f8f8f8;
}
.visit-memorable-client.real-clients-section {
    background-color: #fff;
}
.visit-memorable-client.reverse .play-anim-ico {
    left: auto;
    margin: 0;
    right: 20px;
    top: 20px;
}
.visit-memorable-client .videohol {
    position: relative;
}
.visit-memorable-client.reverse ~ .visit-memorable-client .memorable-left {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.visit-memorable-client-inner {
    max-width: var(--container-width);
    margin: 0 auto;
    padding: 0 15px;
}
.flex-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.memorable-left {
    width: 49%;
}
.memorable-right {
    width: 49%;
}
.visit-memorable-client.reverse {
    padding-bottom: 40px;
}
.flex-flow-reviews .clientvideos {
    display: block;
    position: relative;
}
.flex-flow-reviews {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-bottom: 10px;
}
.realclient-testimonial-right {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
}
.video-capt {
    color: var(--orange);
    font-size: 16px;
    font-weight: 600;
    margin-top: 5px;
}
.flex-flow-reviews .clientvideos img,
.square-reviews-box img {
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.square-reviews-box a {
    display: block;
    position: relative;
}
.flex-flow-reviews .clientvideos:after,
.square-reviews-box a:after {
    content: '';
    background-image: url(../images/home-page/play-button-org.svg);
    width: 37px;
    height: 37px;
    background-size: contain;
    position: absolute;
    left: 10px;
    top: 10px;
}
.square-reviews-box a:before {
    content: '';
    width: 100%;
    display: block;
    padding-top: 92%;
}
.square-reviews-box a img {
    position: absolute;
    left: 0;
    top: 0;
}
.realclient-testimonial-right .revies-button-block {grid-column: span 2;}
.realclient-testimonial-right .revies-button-block .common-btn {
    font-size: 28px;
    min-height: 100%;
    font-weight: 600;
    text-align: center;
}
.realclient-testimonial-right .revies-button-block .common-btn:before {
    content: '';
    position: absolute;
    left: 14px;
    top: 14px;
    bottom: 14px;
    right: 14px;
    border-width: 1px;
    border-style: solid;
    border-color: #fff;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    left: 15px;
    top: 15px;
    right: 15px;
    bottom: 15px;
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    background-color: transparent;
}
.realclient-testimonial-right .revies-button-block .common-btn:hover {
    background-color: var(--orange-dark);
}
.flex-flow-reviews .clientvideos::before {
    content: '';
    padding-top: 61.13%;
    width: 100%;
    display: block;
}
.flex-flow-reviews .clientvideos img {
    position: absolute;
    left: 0;
    top: 0;
}
/*************************************/
.inq_form {
    padding: 40px 0 40px 0;
    background-color: #f8f8f8;
}
.inq_form .common-page-hd {
    margin-bottom: 20px;
}
.inq_form-inner {
    max-width: var(--container-width);
    margin: 0 auto;
    padding: 0 15px;
}
.inq_form_row {
    box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.1);
    padding: 10px;
    display: grid;
    grid-template-columns: 1.5fr 3fr;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    background-color: #fff;
}
.inq_form_left {
    background-color: #000;
    background-image: url(../images/home-page/contact-shape.png);
    background-size: 100%;
    background-position: bottom right;
    background-repeat: no-repeat;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 30px;
}
.inq_form_right {
    padding: 30px;
}
.inq_form input[type=Password],
.inq_form input[type=text],
.inq_form input[type=tel],
.inq_form input[type=email],
.inq_form textarea {
    padding: 8px 0 10px 0;
    font-size: 16px;
    border-width: 0 0 1px 0;
    border-color: #C1C1C1;
    border-style: solid;
    width: 100%;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    font-family: 'Exo 2';
    font-weight: bold;
    font-size: 16px;
    color: #000;
}
.input-row-flow label {
    font-family: 'Exo 2';
    font-size: 16px;
    color: #656565;
    font-weight: 500;
}
.inq_form-left {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
}
.input-row-flow:last-child {
    grid-column: span 2;
}
.inq_form_row .button-btn {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    margin-top: 20px;
    justify-content: space-between;
}
.inq_form ul.banner_contact-data {
    display: grid;
    gap: 15px;
    margin-top: 20px;
}
.inq_form ul.banner_contact-data a {
    color: #fff;
}
.common-page-hd.white{
    color: #fff;
}
.common-page-hd.white strong:before{
    background-color: #fff;
}
/*************************************/
@media screen and (max-width:1600px) {
    .banner-section-inner {
        grid-template-columns: 1.8fr 1fr;
    }
    .banner-section h1 {
        font-size: 44px;
    }
    .banner-section h1 + strong {
        font-size: 36px;
    }
    .banner-section p {
        line-height: 22px;
        font-size: 15px;
    }
    .banner-section ul li {
        line-height: normal;
        font-size: 15px;
    }
    .banner-section strong {
        font-size: 30px;
        margin-bottom: 10px;
    }
}
@media screen and (max-width:1366px) {
    ul.banner_contact-data {
        gap: 20px;
    }
    ul.quick-links {
        gap: 20px;
    }
    .our-process ul li i:after {
        left: 155%;
    }
    .visit-memorable-client .videohol {
        max-width: 100%;
        width: 100%;
    }
    .kubinga-image-block {
        width: 100%;
    }
    section.gojek-clone-app.reverse .screens_holder {
        left: auto;
        right: 10px;
    }
    .gojek-clone-app.reverse .gojek-clone-app-right {
        padding: 20px 10px 20px 0;
    }
    .screens_holder {
        left: 10px;
    }
}
@media screen and (max-width:1100px) {
    .our-products-section ul {
        grid-template-columns: repeat(4, 1fr);
    }
    .flex-flow-reviews {
        gap: 20px;
    }
}
@media screen and (max-width:1024px) {
    .our-process ul {
        grid-template-columns: repeat(3, 1fr);
    }
    .our-process ul li i:after {
        display: none;
    }
    .our-process::after {
        display: none;
    }
    .visit-memorable-client {
        background-color: #f8f8f8;
    }
    .angola-inner {
        align-items: center;
    }
    .banner-section-inner,
    .main-product-clone-row {
        align-items: center;
    }
    .clients-left {
        width: 100%;
    }
    .clients-right {
        width: 100%;
    }
    .gojek-clone-app .screens_holder {
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
    }
    .angola-inner {
        align-items: center;
    }
}
@media screen and (max-width:991px) {
    .banner-section-inner {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        gap: 20px;
    }
    .banner-left {
        width: 100%;
    }
    .banner_contact {
        display: none;
    }
    .banner-section {
        padding: 120px 0 40px 0;
    }
    .banner-section h1 + strong {
        font-size: 27px;
    }
    .banner-section h1 {
        font-size: 40px;
    }
    .banner-section strong {
        font-size: 28px;
    }
    .main-product-clone .main-product-clone-right > a {
        max-width: 647px;
    }
    .banner-left img {
        max-width: 400px;
        margin: 0 auto;
        display: block;
    }
    .banner-left {
        max-width: 400px;
    }
    .banner-left:before {
        padding-top: 91%;
    }
    /******************************/
    /******************************/
    .gojek-clone-app-left,
    .gojek-clone-app-right,
    .gojek-clone-app.reverse .gojek-clone-app-right,
    .gojek-clone-app.reverse .gojek-clone-app-left {
        width: 100%;
    }
    .gojek-clone-app-right img:first-child,
    .gojek-clone-app-right img:last-child {
        width: auto;
    }
    .gojek-clone-app.reverse .gojek-clone-app-right {
        padding: 20px;
        justify-content: center;
    }
    section.gojek-clone-app.reverse .screens_holder {
        top: 18px;
        left: 0;
        right: auto;
        transform: none ;
        -webkit-transform: none ;
        -moz-transform: none ;
        -ms-transform: none ;
        -o-transform: none ;
    }
    section.gojek-clone-app.reverse .screens_holder {
        justify-content: center;
    }
    .gojek-clone-app .screens_holder {
        top: 18px;
        transform: none;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
        left: 25px;
    }
    .gojek-clone-app-right::after,
    .gojek-clone-app.reverse .gojek-clone-app-right::after {
        width: 100%;
        border-radius: 20px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
    }
    .gojek-clone-app .gojek-clone-app-inner {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
    .gojek-clone-app.reverse .gojek-clone-app-inner {
        flex-direction: column;
    }
    .gojek-clone-app-left,
    .gojek-clone-app-right {
        width: 100%;
        justify-content: center;
        min-height: initial;
    }
    .gojek-clone-app-right {
        padding: 10px;
        max-width: 670px;
        margin: 0 auto;
    }
    .apps-launched-inner {
        grid-template-columns: 1fr;
        gap: 20px;
    }
    .apps-launched img {
        max-width: 357px;
    }
    .apps-launched-left {
        text-align: center;
    }
    .apps-launched-left img {
        vertical-align: top;
    }
    .gojek-clone-app-right .screen {
        max-width: 300px;
        height: 615px;
        top: auto !important;
        bottom: auto !important;
    }
    .gojek-clone-app-right:before {
        content: '';
        display: block;
        width: 100%;
        padding-top: 100%;
    }
    /***************************/
    .main-product-clone-row {
        margin: 10px 0 20px 0;
        gap: 20px;
        flex-direction: column-reverse;
        display: flex;
    }
    /***************************/
    .statistics-row {
        grid-template-columns: 1fr;
        gap: 20px;
    }
    ul.statistics-list {
        gap: 20px;
    }
    /*****************************/
    .clients-section-inner {
        gap: 20px;
    }
    .clients-left {
        width: 100%;
    }
    .clients-right {
        width: 100%;
    }
    /*****************************/
    .memorable-left {
        width: 100%;
    }
    .memorable-right {
        width: 100%;
    }
    .visit-memorable-client .videohol img {
        width: 100%;
    }
    /*****************************/
    .prelaunch-event-inner {
        flex-direction: column-reverse;
        gap: 20px;
    }
    .prelaunch-left {
        width: 100%;
    }
    .prelaunch-right {
        width: 100%;
        text-align: center;
    }
    .prelaunch-video {
        width: auto;
        max-width: 792px;
        margin: 0 auto;
    }
    /*****************************/
    .our-products-section ul {
        grid-template-columns: repeat(3, 1fr);
    }
    /*****************************/
    .first-billion-section-inner {
        display: flex;
        flex-direction: column;
    }
    .first-billion-img {
        width: 480px;
        margin: 0 auto;
        display: block;
        max-width: 100%;
        text-align: center;
    }
    /*****************************/
    ul.features-row,
    ul.core-values-row {
        grid-template-columns: 1fr 1fr;
    }
    ul.features-row.core_value_list li:last-child {
        grid-column: span 2;
    }
    /*****************************/
    .clients-visitus ul {
        grid-template-columns: 1fr 1fr;
    }
    .clients-visitus ul li:last-child {
        grid-column: span 2;
    }
    /*****************************/
    .angola-inner {
        gap: 20px;
    }
    .angola .heading-area {
        margin-bottom: 0;
    }
    .angola-left {
        width: 100%;
    }
    .angola-right {
        width: 100%;
        text-align: center;
    }
    .kubinga-image-block {
        width: auto;
        max-width: 803px;
        margin: 0 auto;
    }
    /*****************************/
    .flex-row {
        grid-template-columns: 1fr;
        gap: 20px;
    }
    .visit-memorable-client.reverse .flex-row {
        flex-direction: column-reverse;
        display: flex;
        flex-wrap: wrap;
    }
    /*****************************/
    .inq_form_row {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
    /*****************************/
    .video-capt {
        font-size: 14px;
    }
    .flex-flow-reviews {
        grid-template-columns: 0.9fr 1.1fr;
    }
    .realclient-testimonial-right {
        gap: 10px;
    }
    .flex-flow-reviews .clientvideos {
        max-width: 654px;
    }
    /*****************************/
    .our-process ul {
        grid-template-columns: repeat(2, 1fr);
    }
    .our-process ul li:last-child {
        grid-column: span 2;
    }
    /*****************************/
    .common-page-hd {
        font-size: 35px;
    }
    .gojek-clone-app .common-page-hd {
        text-align: center;
    }
    .common-page-hd ~ strong,
    .common-page-hd span {
        font-size: 22px;
    }
    .masonry-block_ a.view-more>span {
        font-size: 28px;
    }
    .clients-visitus ul li .big-button .common-btn {
        font-size: 30px;
        padding: 30px;
    }
}
@media screen and (max-width:767px) {
    .banner-section h1 + strong {
        font-size: 24px;
    }
    .banner-section h1 {
        font-size: 37px;
    }
    .banner-section strong {
        font-size: 26px;
    }
    .our-products-section ul {
        grid-template-columns: repeat(2, 1fr);
    }
    .our-products-section ul li b {
        font-size: 16px;
    }
    ul.features-row,
    ul.core-values-row {
        grid-template-columns: 1fr;
    }
    .launching.text-white + .features {
        background-color: #fff;
    }
    .launching-inner {
        display: flex;
        flex-direction: column-reverse;
        gap: 20px;
    }
    .clients-visitus ul {
        display: flex;
        flex-wrap: wrap;
    }
    .clients-visitus ul li {
        width: 100%;
    }
    .inq_form_right {
        padding: 0;
    }
    .inq_form_left {
        margin-top: 20px;
    }
    .inq_form_row .button-btn {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }
    .flex-flow-reviews {
        grid-template-columns: 1fr;
    }
    .our-process ul {
        display: flex;
        flex-wrap: wrap;
    }
    .common-page-hd {
        font-size: 30px;
    }
    .common-page-hd ~ strong,
    .common-page-hd span {
        font-size: 20px;
    }
    .first-billion-cnt p {
        width: auto;
        text-align: justify;
        margin-bottom: 0;
    }
    .first-billion-cnt {
        align-items: flex-start;
    }
    .first-billion-cnt .common-page-hd.center ~ strong:before {
        left: 0;
        transform: translatex(0);
    }
    .first-billion-cnt .common-page-hd ~ strong {
        margin: 0 0 10px 0;
    }
    .first-billion-section {
        padding: 40px 0;
        background-color: #ba3809;
    }
    .features + .features {
        background-color: #f8f8f8;
    }
    .masonry-block_ a.view-more>span {
        font-size: 30px;
        padding: 0 10px;
        text-align: center;
    }
    .innovative-solutions {
        padding: 40px 0 10px 0;
    }
    .features ~ .inq_form {
        background-color: #f8f8f8;
    }
    .gojek-clone-app-right .screen:first-child {
        top: auto;
        width: auto;
    }
    .gojek-clone-app-right,
    .gojek-clone-app.reverse .gojek-clone-app-right {
        padding: 0;
        background-color: transparent;
        text-align: center;
    }
    .screens_holder, section.gojek-clone-app.reverse .screens_holder {
        position: relative;
        top: auto;
        left: 0;
        display: inline-block;
        width: 300px;
    }
    .gojek-clone-app-right:before {
        display: none;
    }
    .gojek-clone-app-right::after {
        display: none;
    }
}
@media screen and (max-width:630px) {
    [class*="client-img"] a .play-anim-ico {
        top: auto;
        bottom: 20px;
        right: 20px;
        width: 30px;
        height: 30px;
    }
    .inq_form-left {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
    }
    .input-row-flow {
        width: 100%;
    }
    ul.common-listing.grid {
        grid-template-columns: 1fr 1fr;
    }
    :root {
        --button-padding: 15px 20px 15px 20px;
    }
}
@media screen and (max-width:480px) {
    ul.statistics-list li strong {
        font-size: 30px;
    }
    .our-products-section ul li a {
        padding: 10px;
    }
    .our-products-section ul li {
        padding: 10px;
    }
    .our-products-section ul li span {
        bottom: 50px;
    }
    .our-products-section ul li img {
        height: 35px;
        width: 35px;
    }
    .realclient-testimonial-right {
        grid-template-columns: 1fr 1fr;
    }
    ul.common-listing.grid {
        grid-template-columns: 1fr;
    }
}
@media screen and (max-width:360px) {
    ul.statistics-list li strong {
        font-size: 20px;
        height: 90px;
    }
    ul.statistics-list li span {
        font-size: 15px;
    }
    p {
        font-size: 15px;
        line-height: 21px;
    }
    .inq_form_row {
        overflow: hidden;
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 1.50) and (max-width:1920px) {
    .banner-section-inner {
        height: auto;
    }
}
@media screen and (-webkit-min-device-pixel-ratio: 1.25) and (max-width:1920px) {
    .banner-section-inner {
        grid-template-columns: 1.8fr 1.2fr;
    }
    .banner-left {
        padding-top: 0;
    }
}
@media screen and (-webkit-min-device-pixel-ratio: 1) and (min-width:1800px) and (max-width:1920px) {
    .banner-section-inner {
        grid-template-columns: 1fr 1fr;
        max-width: 1359px;
    }
    .banner-section strong {
        font-size: 34px;
    }
    .banner-section p {
        font-size: 17px;
        margin-bottom: 15px;
    }
    .banner-section ul li {
        font-size: 17px;
        line-height: 25px;
        margin-bottom: 15px;
    }
    .banner-section ul li:first-child,
    .banner-section ul li:nth-child(2) {
        font-size: 16px;
    }
    .banner-section h1 {
        font-size: 48px;
    }
    .banner-section h1 + strong {
        font-size: 38px;
    }
    .banner-section .heading-caption {
        margin: 0 0 20px 0;
    }
}
@media all and (-webkit-max-device-pixel-ratio: 1) and (max-height: 830px){
    .banner-section-inner {
        height: auto;
    }
}
@media screen and (min-width:1921px) {
    .angola-right a {
        display: block;
        width: 806px;
    }
    .prelaunch-video {
        display: block;
        width: 796px;
    }
}